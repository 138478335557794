<template>
  <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  
  <v-row style="background: white;" justify="center">
    <v-col  cols="12" lg="8" xl="6" xxl="4">
      <v-img :src="topImage" max-width="100%" aspect-ratio="1.72"/>
    </v-col>
  </v-row>
  <v-row style="background: white;" justify="center">
    <v-col cols="12" lg="8" xl="6" xxl="4" class="text-h6"
      style="
        whiteSpace: pre-line;
        textAlign: center;
      "
    >
      {{ introText }}
    </v-col>
  </v-row>

</template>

<script>
// import constants from "../common/constants.js"

export default {
  props: {
  },
  data() {
    return {
      circleLogoImage: "/images/circle_logo.png",
      topImage: "/images/top.png",

      introText:
        "常人には見えない不可視の異形へと変じる人類、『デタリアン』。\n" + 
        "人を食らうデタリアンと、それを狩る『見える』人間。\n" + 
        "有史以来、拮抗している人類の座を賭けた戦いは、度々変革を迎える。\n" + 
        "",
    }
  },
}
</script>