<template>
  <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  
  <v-row style="background: white;" justify="center">
    <v-col  cols="12" lg="8" xl="6" xxl="4">
      <v-img :src="topImage" max-width="100%" aspect-ratio="1.72"/>
    </v-col>
  </v-row>
  <v-row style="background: white;" justify="center">
    <v-col cols="12" lg="8" xl="6" xxl="4" class="text-h5">{{ circleIntro }}</v-col>
  </v-row>

</template>

<script>
// import constants from "../common/constants.js"

export default {
  props: {
  },
  data() {
    return {
      circleLogoImage: "/images/circle_logo.png",
      topImage: "/images/top.png",

      circleIntro: "ラジャフルテールは創作漫画サークルです！！",
    }
  },
}
</script>