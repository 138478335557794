import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


// Vuetify適用
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    // ハンバーガーメニューのアイコンとか
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: "#F2CB6A", // カスタムのプライマリカラー
        secondary: "#d7ee99", // 他のVuetifyのカラーから選択
        // 他のカスタムカラーも追加できます
        mycolor: "#d7ee99",
      },
    },
  }
})

createApp(App).use(router).use(vuetify).mount('#app')
