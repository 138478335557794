<template>
  <v-app style="background: blue;">
    <v-main style="background: white;">
      <!-- ヘッダー -->
      <header>
        <myheader :mainMenus="mainMenus"></myHeader>
      </header>
    
      <!-- 各ページ表示 -->
      <v-container style="max-width: 100%;">
        <router-view></router-view>
      </v-container>

      <!-- フッター -->
      <footer>
        &copy; 2012-2024 ラジャフルテール / 深淵の君
      </footer>

    </v-main>
  </v-app>
</template>

<script>
import MyHeader from "./common/MyHeader.vue"
// import constants from "../common/constants.js"

export default {
  name: 'app',
  components: {
    myheader: MyHeader,
  },
  data() {
    return {
      mainMenus: [
        {
          name: "HOME",
          url: "/"
        },
        {
          name: "SHINEN",
          url: "/about-shinen"
        },
        {
          name: "CHARACTERS",
          url: "/characters"
        },
        {
          name: "IGYO",
          url: "/igyos"
        },
        {
          name: "LINK",
          url: "https://www.google.com/"
        },
      ],
    }
  },
  mounted() {
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
