import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/RajaHome.vue'
import ShinenCharacters from '../components/ShinenCharacters.vue'
import ShinenAbstract from '../components/ShinenAbstract.vue'
import ShinenIgyos from '../components/ShinenIgyos.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/characters', component: ShinenCharacters },
  { path: '/about-shinen', component: ShinenAbstract },
  { path: '/igyos', component: ShinenIgyos }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router