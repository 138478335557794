<template>
      <v-app-bar color="#F2CB6A" elevation="1" flat>
        <!-- タブメニュー表示(PC時) -->
        <v-tabs v-if="!isMobile">
          <v-tab v-for="mainMenu in mainMenus" :key="mainMenu.name" @click="navigateTo(mainMenu.url)">
            {{ mainMenu.name }}
          </v-tab>
        </v-tabs>
        <!-- ハンバーガーメニュー(スマホ時) -->
        <v-app-bar-nav-icon v-if="isMobile" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-app-bar-title>RAJA FULL TAIL</v-app-bar-title>
      </v-app-bar>
      <!-- ハンバーガーメニューのリスト -->
      <v-navigation-drawer v-model="drawer" fixed temporary>
        <v-list>
          <v-list-item v-for="mainMenu in mainMenus" :key="mainMenu.name" @click="navigateTo(mainMenu.url)">
            <v-list-item-title>
              {{ mainMenu.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

</template>

<script>
export default {
  name: 'MyHeader',
  props: {
    mainMenus: [],
  },
  data() {
    return {
      circleLogoImage: "./images/circle_logo.png",
      drawer: false,  // ハンバーガーメニューのプルダウン表示
      isMobile: false,  // 表示ブラウザがモバイル端末か
    }
  },
  mounted() {
    // ウィンドウのリサイズ時発火のリスナーを追加
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にリスナーを削除
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      // ウィンドウのリサイズ時に再評価
      // console.log("windowサイズ = " + window.innerWidth);
      if(window.innerWidth <= 840) {
        this.isMobile = true;
      }else {
        this.isMobile = false;
      }
    },
    navigateTo(url) {
      // location.href = url;
      this.$router.push(url);
    },
  },
}
</script>



<style scoped>
.header-gradient-card {
  background: linear-gradient(135deg, #F2CB6A, #d7ee99);
}
</style>