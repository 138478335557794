<template>

      <v-row no-gutters justify="center">
        <v-col cols="12" md="4" lg="4" xl="4" xxl="2">
          <div class="text-subtitle-1">- 人物紹介 -</div>
          <v-expansion-panels v-model="charaId" variant="accordion">
            <v-expansion-panel @click="setCharacterId()"
              v-for="(character) in characters"
              :style="{
                backgroundColor: (character.id==charaId)? '#222222' : 'white',
                color: (character.id==charaId)? 'white' : 'black',
                whiteSpace: 'pre-line',
                textAlign: 'left',
              }"
              :key="character.id"
            >
              <v-expansion-panel-title>
                <span :style="{ color: (character.id == charaId) ? 'white' : 'black' }">
                  {{ character.name }}

                  <span class="text-body-5"> -- {{ character.kana }}</span>
                  </span>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p>{{ character.remark }}</p>
                <!-- <v-img v-if="character.image" :src="charaImage"></v-img> -->
              </v-expansion-panel-text>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <!-- 画像表示 -->
        <v-col cols="12" md="8" lg="8" xl="8" xxl="4">
          <v-img :src="backgroundImage">
            <v-img style="top: 0%; align-items: center; max-width: 500px;" :src="charaImage"/>
          </v-img>
        </v-col>
        <!-- <v-col cols="12" lg="8" xl="6" xxl="4"
          class="my-gradient-card"
          elevation="3"
        >
          <div class="text-center">
            <v-img :src="charaImage" max-width="500px"/>
          </div>
        </v-col> -->

      </v-row>


</template>

<script>

export default {
  data() {
    return {
      backgroundImage: "/images/school.png",

      charaImageTemplateFore: "/images/chara_",
      charaImageTemplateRear: ".png",

      characters: [], // {name, image, igyo},

      charaId: null,
      charaName: null,
      charaImage: null,
    }
  },
  mounted() {
    this.characters = [
      {
        id: 0, name: "灰海　カジ", kana: "Kaji Haimi", image: "haimi", igyo: "thunder", 
        remark: "淵ヶ谷高校に転校してきたデタリアンの少女。\n" +
          "来るデタリアンの女王選定に備え手駒を探しており、志波に目を付ける。\n" +
          "雷雲を呼ぶ強大な異形、『サンダーフォニカ』を持つ。"
      },
      {
        id: 1, name: "志波　双太郎", kana: "Sotaro Shiba", image: "shiba", igyo: "kunai", 
        remark: "淵ヶ谷高校２年。\n" +
          "己の凡庸さを内心で自覚しつつ日々を送っていたところ、\n" +
          "カジに自身がデタリアンであることを告げられる。\n" +
          "俊敏性に優れる小型恐竜のような異形を持つ。"
      },
      {
        id: 2, name: "木間　優郎", kana: "Yuro Kima", image: "kima", igyo: null, 
        remark: "秘密裏に対デタリアン用に武装した警備会社『淵ヶ谷エリア保障』課長。\n" +
          "デタリアンの異形を肉眼で視認できる稀有な資質を持つ、腕利きの接触者。"
      },
      {
        id: 3, name: "右堂　雪衣", kana: "Setsui Udo", image: "setsui", igyo: null, 
        remark: "『淵ヶ谷エリア保障』入社3年目の新人接触者。\n" +
          "入社当初は事務職としての採用だったが、紆余曲折を経てなし崩し的にセンサーとして仕立て上げられる。"
      },
    ];

  },
  methods: {
    setCharacterId() {
      if(typeof this.charaId == "undefined"){
        this.charaName = null;
        this.charaImage = null;
        return;
      }

      this.charaName = this.characters[this.charaId].name;
      this.charaImage = this.charaImageTemplateFore + this.characters[this.charaId].image + this.charaImageTemplateRear;
      console.log("chara image = " + this.charaImage);
    }
  }
}
</script>

<style scoped>
.my-gradient-card {
  background: linear-gradient(135deg, #c0a98e, #7ba1e7);
}

.v-expansion-panel-fixed {
  max-height: calc(4 * 48px); /* 4つ分のパネルの高さ */
  overflow-y: auto; /* スクロール可能にする */
}
</style>